/* eslint-disable object-property-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTranslation, Trans } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import classnames from 'classnames';
import Bugsnag from '@bugsnag/js';
import Option from './option';
import classes from './classes.module.scss';
import { purchaseDetailsTypes, paymentSourcesTheme } from '../config/const';
import { PaymentSourcesListType } from '../payment_methods_list/payment_sources_prop_types';
import { getMinPrice, isPOW, isPaygAndNotEligibleToPayAsYouGo } from '../config/utils';
import { applePayIsSupported, renderFeesLabel, iapIsSupported } from '../purchase_details_common/purchase_details_common_utils';
import { formatCurrency, getActiveOffer } from '../../app/config/util';
import ProcessApplePay from './process_apple_pay';

const neetTopBorder = ({ type }) => type !== purchaseDetailsTypes.AFFILIATE_PROMO_POPUP;

const getContainerStyle = ({ type }) => {
  if (neetTopBorder({ type })) return {};
  return { borderTop: 'none' };
};

const renderPriceInfo = ({ isMinPurchase, t }) => {
  if (!isMinPurchase) return null;
  return (
    <div className={ classes.priceInfo }>
      <div className={ classes.priceInfoStar }>*</div>
      <div className={ classes.priceInfoText }>{t('chat.purchase_min', { price: getMinPrice() })}</div>
    </div>
  );
};

const renderAffiliatePromoExpiresAffiliatePromoPopup = ({ type, renderOfferExpires }) => {
  if (type !== purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) return null;
  return renderOfferExpires();
};

const renderPrompt = ({ hasValidOptions, options, t }) =>  {
  if (!(hasValidOptions && options.length > 1)) return null;
  return <span className={ classes.selectOptions }>{ t('purchase.select_option') }</span>;
};

const getOptions = ({ hasValidOptions, options }) => {
  if (hasValidOptions) return options;
  return [{ kind: 'cc', humanKind: 'Credit or Debit Card' }, { kind: 'pp', humanKind: 'PayPal' }];
};

const renderOptions = ({ options, optionProps }) => {
  const { setInAppPaymentOptions } = optionProps;
  const isAdding = options.find((o) => o.adding);
  if (isPOW() && isAdding && setInAppPaymentOptions) {
    setTimeout(() => { setInAppPaymentOptions('iap'); }, 0);
    return null;
  }
  return options.map((o, index) => (<Option key={ index } { ...o } { ...optionProps } />));
};
const renderContent = ({ hasValidOptions, options, optionProps }) => {
  const { loading, creditCardValidateError } = optionProps;
  if (!hasValidOptions && !loading) return null;
  if (creditCardValidateError) return null;
  const updatedOptions = getOptions({ hasValidOptions, options });
  return renderOptions({ options: updatedOptions, optionProps });
};

const renderNoThanksLink = ({ type, hideAffiliatePromoPopupAction, t }) => {
  if (type !== purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) return null;
  return <button className={ classes.noThanksLink } onClick={ hideAffiliatePromoPopupAction } type="button">{ t('purchase.maybe_later') }</button>;
};

const renderAffiliatePromobuttonText = (buttonTitleText, buttonTitleBonus) => (
  <div className={ classes.buttonTitleContainer }>
    <div className={ classes.buttonTitleText }>
      { buttonTitleText }
      {buttonTitleBonus}
    </div>
  </div>
);

const buttonText = ({
  type, t, requiredCredit, purchaseFees
}) => {
  switch (type) {
    case purchaseDetailsTypes.AFFILIATE_PROMO_POPUP: {
      const activeOffer = getActiveOffer();
      if (!activeOffer) return null;
      const buttonTitleText = t('purchase.affiliate_promo_popup_button_title', { amount: formatCurrency(parseFloat(activeOffer.requiredPurchasesAmount) + purchaseFees) });
      const buttonTitleBonus = t('purchase.affiliate_promo_popup_button_bonus', { amount: formatCurrency(parseFloat(activeOffer.bonus)) });
      return renderAffiliatePromobuttonText(buttonTitleText, buttonTitleBonus);
    }
    default: { return t('purchase.purchase_pay', { amount: (parseFloat(requiredCredit) + purchaseFees).toFixed(2) }); }
  }
};

const logIssueWithPurchase = (options) => {
  try {
    const paymentSources = window.store?.getState().paymentSources;
    if (!paymentSources?.current) return;
    const optionKind = options[0]?.kind;
    const sourceKind = paymentSources?.current?.kind;
    if (options?.length === 1 && sourceKind !== optionKind) {
      Bugsnag.notify('Payment with wrong payment method', (e) => {
        e.severity = 'warning';
      });
    }
  } catch (e) { /* empty */ }
};

const renderBuyButtonContent = ({
  options, forMobileBuyBtn, renderCreditCardProcessing, renderPayPalProcessing, disableBuyButton,
  isMinPurchase, purchaseURL, purchAmount, type, purchaseAmount, bonus, t, requiredCredit, chatType,
  purchaseFees, isPayg, renderCreditCardValidateProcessing, requiresPayAsYouGoValidation, onProcessing,
  isContinue, onPurchaseComplete, cancelChatOnPP, hideAffiliatePromoPopupAction
}) => {
  const shouldDisableBuyButton = disableBuyButton || (options[0].kind === 'ap' && !applePayIsSupported());
  logIssueWithPurchase(options);
  if (forMobileBuyBtn) return forMobileBuyBtn({ disableBuyButton: shouldDisableBuyButton, isPayg, requiresPayAsYouGoValidation });
  if (renderCreditCardValidateProcessing && isPayg) return renderCreditCardValidateProcessing({ disableBuyButton, requiresPayAsYouGoValidation });
  if (options[0].kind === 'cc') {
    return (
      <div className={ classes.buyButtonContainer }>
        {renderCreditCardProcessing(
          disableBuyButton,
          isMinPurchase,
          options[0],
          purchaseURL,
          purchAmount,
          () => (
            <div className={ classnames(classes.buyButton, disableBuyButton ? classes.disabledBuyButton : '', classes[`buyButton${ type }`])  }>
              { buttonText({
                type, purchaseAmount, bonus, t, requiredCredit, purchaseFees
              }) }
            </div>
          )
        )}
      </div>
    );
  }
  if (options[0].kind === 'ap') {
    return (
      <ProcessApplePay
        { ...options[0] }
        currentPaymentOption={ options[0] }
        purchaseAmount={ purchAmount }
        disabled={ shouldDisableBuyButton }
        purchaseURL={ purchaseURL }
        chatType={ chatType }
        bonus={ bonus }
        onProcessing={ onProcessing }
        isContinue={ isContinue }
        onPurchaseComplete={ onPurchaseComplete }
        cancelChatOnPP={ cancelChatOnPP }
        type={ type }
        hideAffiliatePromoPopupAction={ hideAffiliatePromoPopupAction }
        purchaseFees={ purchaseFees }
      >
        <div
          className={ classnames(classes.buyButton, shouldDisableBuyButton ? classes.disabledBuyButton : '', classes[`buyButton${ type }`])  }
          style={ { marginTop: '15px' } }
        >
          {
            buttonText({
              type, purchaseAmount, bonus, t, requiredCredit, purchaseFees
            })
          }
        </div>
      </ProcessApplePay>
    );
  }
  return (
    <div className={ classes.buyButtonContainer }>
      {renderPayPalProcessing(
        disableBuyButton,
        isMinPurchase,
        options[0],
        purchaseURL,
        purchAmount,
        () => (
          <div className={ classnames(classes.buyButton, disableBuyButton ? classes.disabledBuyButton : '', classes[`buyButton${ type }`])  }>
            { buttonText({
              type, t, requiredCredit, purchaseFees
            }) }
          </div>
        )
      )}
    </div>
  );
};

function PaymentOptions({
  options, isMinPurchase, purchaseURL, type, purchaseAmount, requiredCredit, theme,
  loadingOptions, loading, paymentSources, renderPayPalProcessing, paymentProcessing,
  changingSource, bonus, showPaymentSources, chatType, onProcessing, isContinue,
  addCreditCard, renderCreditCardProcessing, getCurrentLocalization, forMobileBuyBtn,
  setInAppPaymentOptions, fees, isPayg, renderCreditCardValidateProcessing, creditCardValidateError, hideAffiliatePromoPopupAction, onPurchaseComplete, cancelChatOnPP
}) {
  const { t } = useTranslation();
  const { purchaseFees, purchaseFeesText } = fees;

  const hasValidOptions = options && options.length > 0;
  const noContextOption = hasValidOptions && options.find(e => !!e.context);

  const renderOfferExpires = () => {
    const locale = { en: enUS, es };
    const expireDate = getActiveOffer()?.expiresAt;
    if (!expireDate) return null;
    const dateDiff = parseInt((new Date(expireDate) - Date.now()) / (1000 * 60 * 60 * 24), 10);
    if (dateDiff > 7) return null;
    const dateString = format(new Date(expireDate), 'MMMM dd, yyyy', { locale: locale[getCurrentLocalization()] });
    return (
      <div className={ classes.offerExpiresDateContainer }>
        <Trans
          i18nKey="purchase.offer_expires_at_midnight_ET"
          components={ {
            bold: <span className={ classes.offerExpiresDateBoldText } />,
            brIfNeed: <br className={ classes.offerExpiresBrIfNeed } />
          } }
          values={ { dateString } }
        />
      </div>
    );
  };

  const renderBuyButton = () => {
    if (!(hasValidOptions && noContextOption)) return null;
    const eligibleToPayAsYouGo = paymentSources?.current?.eligibleToPayAsYouGo;
    const requiresPayAsYouGoValidation =  paymentSources?.current?.requiresPayAsYouGoValidation;
    const disableBuyButton = options.find((o) => o.errorText)
      || (isPaygAndNotEligibleToPayAsYouGo({ isPayg, eligibleToPayAsYouGo }))
      || (options.find((o) => o.kind === 'iap') && !iapIsSupported());
    const purchAmount = parseFloat(requiredCredit);
    return renderBuyButtonContent({
      options, forMobileBuyBtn, renderCreditCardProcessing, renderPayPalProcessing, bonus, t, onProcessing,
      disableBuyButton, isMinPurchase, purchaseURL, purchAmount, type, purchaseAmount, chatType, isContinue,
      requiredCredit, purchaseFees, isPayg, renderCreditCardValidateProcessing, requiresPayAsYouGoValidation, onPurchaseComplete, cancelChatOnPP, hideAffiliatePromoPopupAction
    });
  };

  const optionProps = {
    purchaseURL, purchaseAmount: parseFloat(requiredCredit), paymentProcessing, showPaymentSources,
    addCreditCard, theme, loading: loading || loadingOptions, paymentSources,
    options, changingSource, setInAppPaymentOptions, isPayg, creditCardValidateError, chatType
  };

  const renderPaymentOptionsContent = () => (
    <>
      { renderPriceInfo({ isMinPurchase, t }) }
      { renderPrompt({ hasValidOptions, options, t }) }
      { renderContent({ hasValidOptions, options, optionProps }) }
      { renderNoThanksLink({ type, hideAffiliatePromoPopupAction, t }) }
      { renderAffiliatePromoExpiresAffiliatePromoPopup({ type, renderOfferExpires }) }
      { renderBuyButton() }
      { renderFeesLabel({ purchaseFeesText, isPayg }) }
    </>
  );

  const renderPaymentOptions = () => (
    <div className={ classes.container } style={ getContainerStyle({ type }) }>
      { renderPaymentOptionsContent() }
    </div>
  );

  return renderPaymentOptions();
}

PaymentOptions.propTypes = {
  options: PropTypes.array,
  requiredCredit: PropTypes.string.isRequired,
  purchaseURL: PropTypes.string.isRequired,
  type: PropTypes.string,
  purchaseAmount: PropTypes.number,
  isMinPurchase: PropTypes.bool,
  bonus: PropTypes.number,
  expiresAt: PropTypes.string,
  renderCreditCardProcessing: PropTypes.func,
  renderPayPalProcessing: PropTypes.func,
  paymentProcessing: PropTypes.func,
  hideAffiliatePromoPopupAction: PropTypes.func,
  showPaymentSources: PropTypes.func,
  addCreditCard: PropTypes.func,
  paymentSources: PropTypes.shape(PaymentSourcesListType),
  loadingOptions: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf([paymentSourcesTheme.purchase, paymentSourcesTheme.wallet]),
  changingSource: PropTypes.bool.isRequired,
  forMobileBuyBtn: PropTypes.func,
  setInAppPaymentOptions: PropTypes.func,
  fees: PropTypes.object,
  isPayg: PropTypes.bool,
  renderCreditCardValidateProcessing: PropTypes.func,
  creditCardValidateError: PropTypes.object,
  onProcessing: PropTypes.func,
  isContinue: PropTypes.bool,
  onPurchaseComplete: PropTypes.func
};

PaymentOptions.defaultProps = {
  options: [],
  bonus: 0,
  expiresAt: null,
  renderCreditCardProcessing: null,
  renderPayPalProcessing: null,
  paymentProcessing: null,
  hideAffiliatePromoPopupAction: null,
  showPaymentSources: null,
  addCreditCard: null,
  paymentSources: null,
  theme: paymentSourcesTheme.purchase,
  forMobileBuyBtn: null,
  setInAppPaymentOptions: null,
  fees: {},
  purchaseAmount: null,
  isMinPurchase: null,
  type: null,
  isPayg: false,
  renderCreditCardValidateProcessing: null,
  creditCardValidateError: null,
  onProcessing: null,
  isContinue: false,
  onPurchaseComplete: null
};

export default PaymentOptions;
