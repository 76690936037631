/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  validateApplePayMerchantApi, editPaymentSourceApi, createCreditCardProcessingPurchaseApi, getCreditCardProcessingDataApi
} from 'app/config/api';
import { log, LOG_LEVELS } from '@common/config/app_logger';
import PollUserCreditStatus from './poll_user_credit_status';
import {
  mpTrackPurchaseStartedEvent, mpTrackPurchaseEvent, mpTrackPurchaseFailedEvent, getFollowupParam, renderButtonTitle, isAffiliatePromoPopup, selectCloseButtonActionIfNeed, buildEventData
} from '../../app/components/purchases/purchases_utils';
import ProcessingStatus from '../../app/components/purchases/processing_status/processing_status';
import { hidePaymentDetails } from '../../app/actions/funds';
import { getUser } from '../../app/actions/user';
import { setMessageToAppData } from '../../app/actions/message_to_app';
import { messageToAppKeys } from '../message_to_app_handler';
import {
  fromMobileApp, browserContext
} from '../config/utils';

const ProcessApplePay = ({
  children, disabled, id, purchaseAmount, context, purchaseURL,
  chatType, bonus, onProcessing, isContinue, type, currentPaymentOption, onPurchaseComplete, cancelChatOnPP, hideAffiliatePromoPopupAction, purchaseFees, clickSource
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const appleSesionRef = useRef(null);
  const { merchantId, ...applePaySession } = context || {};
  const [userCreditId, setUserCreditId] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);

  if (!merchantId || !id || !purchaseAmount) { return null; }

  if (!applePaySession.amount) {
    applePaySession.amount = purchaseAmount;
  }

  const handlePurchase = (purchaseData) => {
    const {
      conversionEventId, extraEventParams, okMessage, cashbackAmount, pathname
    } = purchaseData;

    if (fromMobileApp) {
      const purchaseProps = buildEventData({
        type, purchaseAmount, bonus, currentPaymentOption, chatType, extraEventParams, clickSource
      });
      window.store.dispatch(setMessageToAppData({
        sentToAppMessage: {
          [messageToAppKeys.action]: messageToAppKeys.onPayCompeted,
          [messageToAppKeys.params]: {
            [messageToAppKeys.okMessage]: okMessage,
            [messageToAppKeys.cashback]: cashbackAmount ? parseFloat(cashbackAmount).toFixed(2) : null,
            analytic_events: {
              Purchase: {
                ...purchaseProps
              }
            }
          }
        }
      }));
    } else {
      mpTrackPurchaseEvent({
        type, purchaseAmount, bonus, purchaseFees, conversionEventId, dispatch: window.store.dispatch,
        extraEventParams, currentPaymentOption, chatType, pathname, location
      });
      window.store.dispatch(getUser());
    }
  };

  const handleError = (response, error = null) => {
    const { errorMessage } = response;
    const props = {
      ...response,
      errorMessage: errorMessage ?? error
    };
    if (fromMobileApp) {
      return;
    }
    mpTrackPurchaseFailedEvent({
      ...props, type, purchaseAmount, bonus, purchaseFees, dispatch: window.store.dispatch, chatType, currentPaymentOption, location
    });
  };

  const trackPurchaseStarted = (dataResponse) => {
    if (fromMobileApp) {
      const purchaseStartedProps = buildEventData({
        type, purchaseAmount, bonus, currentPaymentOption, chatType, purchaseFees, clickSource
      });
      window.store.dispatch(setMessageToAppData({
        sentToAppMessage:{
          [messageToAppKeys.action]: messageToAppKeys.analytics,
          [messageToAppKeys.params]: {
            analytic_events: {
              'purchase started': {
                ...purchaseStartedProps
              }
            }
          }
        }
      }));
      return;
    }
    mpTrackPurchaseStartedEvent({
      type, purchaseAmount: parseFloat(purchaseAmount), bonus, purchaseFees, dispatch: window.store.dispatch, ...dataResponse, currentPaymentOption, chatType, location
    });
  };

  const updateProcesing = (p, processingResponse = null) => {
    setProcessing(p);
    if (onProcessing) {
      onProcessing(p, processingResponse);
    }
  };

  const onAppleError = (err) => {
    updateProcesing(false);
    handleError(null, err);
  };

  const onClick = (e) => {
    e.preventDefault();
    if (disabled || processing) { return; }
    updateProcesing(true);

    const params = {
      use_reference_transaction: true,
      with3ds: true,
      ...getFollowupParam({ isContinue, type }),
      ...browserContext
    };

    let dataResponse;

    getCreditCardProcessingDataApi({ url: purchaseURL, params }).then((dataRes) => {
      dataResponse = dataRes;
      trackPurchaseStarted(dataResponse);
    });

    const aps = { ...applePaySession };
    aps.total.amount = purchaseAmount;

    try {
      appleSesionRef.current = new window.ApplePaySession(6, aps);
    } catch (error) {
      onAppleError(e);
      return;
    }

    appleSesionRef.current.oncancel = () => {
      updateProcesing(false);
      mpTrackPurchaseFailedEvent({
        reason: 'user canceled', type, purchaseAmount, bonus, purchaseFees, dispatch: window.store.dispatch, chatType, currentPaymentOption, location, clickSource
      });
    };

    appleSesionRef.current.onvalidatemerchant = ({ validationURL }) => {
      validateApplePayMerchantApi(id, validationURL, purchaseAmount).then((merchantSession) => {
        if (appleSesionRef.current) {
          appleSesionRef.current.completeMerchantValidation(merchantSession);
        }
      }).catch((error) => {
        try {
          if (appleSesionRef.current) { appleSesionRef.current.abort(); }
        } catch (er) {
          onAppleError(er);
        }
        onAppleError(error);
      });
    };

    appleSesionRef.current.onpaymentauthorized = (event) => {
      const { payment } = event || {};
      if (!payment || !payment.token) {
        if (appleSesionRef.current) {
          appleSesionRef.current.completePayment(window.ApplePaySession.STATUS_FAILURE);
        }
        onAppleError('Apple processing failure');
      }

      appleSesionRef.current.completePayment(window.ApplePaySession.STATUS_SUCCESS);

      const { token, billingContact, shippingContact } = payment;

      editPaymentSourceApi({ id, params: { context: { token, billingContact, shippingContact } }, type: 'updatePaymentSourceApi' })
        .then(() => {
          log(LOG_LEVELS.INFO, 'Apple Pay', 'on edit payment source API res');
          if (!appleSesionRef.current) { return; }
          const createPurchaseParams = {
            creditRequested: purchaseAmount,
            ...dataResponse.requiredParams,
            usePaymentSource_id: id,
            ...browserContext
          };
          log(LOG_LEVELS.INFO, 'Apple Pay', `Creating credit card processing with Data res: ${ dataResponse }, paymentSource id: ${ id }, purchase Amount:${ purchaseAmount }`);
          // start polling the value of the user credits
          createCreditCardProcessingPurchaseApi(createPurchaseParams).then((outcome) => {
            if (!appleSesionRef.current) {
              return;
            }
            setUserCreditId(outcome.id);
          }).catch((err) => {
            onAppleError(err);
          });
        }).catch(() => {
          log(LOG_LEVELS.INFO, 'Apple pay', 'On edit payment sources error');
          if (appleSesionRef.current) {
            appleSesionRef.current.completePayment(window.ApplePaySession.STATUS_FAILURE);
            onAppleError('Error on edit source');
          }
        });
    };
    appleSesionRef.current.begin();
  };

  const onUserCreditStatusChange = (isSuccess, response) => {
    updateProcesing(false, response);
    setPaymentResponse(response);
    if (isSuccess) {
      handlePurchase(response);
    } else {
      handleError(response);
    }
  };

  const dissmiss = () => {
    window.store.dispatch(hidePaymentDetails());
  };

  const onConfirmButtonClick = () => {
    dissmiss();
    if (onPurchaseComplete) {
      onPurchaseComplete();
    }
  };

  const onCloseButtonClick = () => {
    dissmiss();
    selectCloseButtonActionIfNeed({ type, cancelChatOnPP, hideAffiliatePromoPopup: hideAffiliatePromoPopupAction });
  };

  const paymentPopup = () => {
    if (fromMobileApp) return null;
    if (processing || !!paymentResponse) {
      return (
        <ProcessingStatus
          purchaseResponce={ paymentResponse || {} }
          visible
          purchaseAmount={ purchaseAmount }
          isAffiliatePromoPopup={ isAffiliatePromoPopup({ type }) }
          isCreditCard
          chatType={ chatType }
          t={ t }
          onConfirm={ onConfirmButtonClick }
          onClose={ onCloseButtonClick }
          onRetry={ onCloseButtonClick }
          buttonTitle={ renderButtonTitle({ isContinue, type, t }) }
          bonus={ bonus }
        />
      );
    }
    return null;
  };

  const processingRender = () => {
    if (processing) {
      return (
        <div>
          {
            userCreditId ? (
              <PollUserCreditStatus id={ userCreditId } onChange={ onUserCreditStatusChange } />
            ) : null
          }
          <div style={ { height:'44px' } }>
            {t('purchase.processing_payment')}
          </div>
        </div>
      );
    }
    return (
      <a
        href="#"
        onClick={ onClick }
        style={ {
          height: '100%', width: '100%', cursor: 'pointer'
        } }
      >
        { children }
      </a>
    );
  };

  return (
    <div
      style={ {
        height: '100%', width: '100%', cursor: 'pointer'
      } }
    >
      {paymentPopup()}
      {processingRender()}
    </div>
  );
};

ProcessApplePay.defaultProps = {
  disabled: false,
  purchaseURL: '',
  chatType: '',
  bonus: 0,
  onProcessing: null,
  isContinue: false,
  type: null,
  currentPaymentOption: null,
  onPurchaseComplete: null,
  cancelChatOnPP: null,
  hideAffiliatePromoPopupAction: null,
  purchaseFees: null,
  clickSource: PropTypes.string
};

ProcessApplePay.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.number.isRequired,
  purchaseAmount: PropTypes.number.isRequired,
  context: PropTypes.object.isRequired,
  purchaseURL: PropTypes.string,
  chatType: PropTypes.string,
  bonus: PropTypes.number,
  onProcessing: PropTypes.func,
  isContinue: PropTypes.bool,
  type: PropTypes.string,
  currentPaymentOption: PropTypes.object,
  onPurchaseComplete: PropTypes.func,
  cancelChatOnPP: PropTypes.func,
  hideAffiliatePromoPopupAction: PropTypes.func,
  purchaseFees: PropTypes.number,
  clickSource: null
};

export default ProcessApplePay;
