import React from 'react';
import parse from 'html-react-parser';
import { Trans } from 'react-i18next';
import classes from '../classes.module.scss';
import Const, { contactUsLink } from '../../../config/const';
import { appRoutes } from '../../../config/app_routes';
import Spiner from '../../../../common/helpers/spiner';
import { appLogo } from '../../../logo/logo';
import { SvgIcon } from '../../../../common/sprites';

const { shared } = window;

const purchasesErrorIcon = require('./img/purchases_flow_error.png');

export const rednerCloseIcon = ({
  okMessage, errorType, isAffiliatePromoPopup, onClose
}) => {
  if (!okMessage && errorType !== 'spending_limits') return null;
  if (isAffiliatePromoPopup) return null;
  return (
    <div className={ classes.closeButtonContent }>
      <button type="button" onClick={ onClose } className={ classes.closeButtonXButton }>
        <SvgIcon id="close_icon" size={ 20 } />
      </button>
    </div>
  );
};

export const getTopImage = ({ isAffiliatePromoPopup, errorMessage }) => {
  if (isAffiliatePromoPopup) return appLogo();
  if (errorMessage) return purchasesErrorIcon;
  return null;
};

export const getTopSection = ({
  errorMessage, t, okMessage,  isAffiliatePromoPopup, purchaseAmount, bonus
}) => {
  let topSection = null;
  if (errorMessage) {
    topSection = (
      <div style={ { paddingTop: '30px' } }>
        <b>{ t('paypal_purchase.error_title') }</b>
      </div>
    );
  } else if (okMessage && !isAffiliatePromoPopup) {
    topSection = (
      <SvgIcon id="purchases_success" size={ 56 } />
    );
  } else if (okMessage && isAffiliatePromoPopup) {
    const amount = purchaseAmount + bonus;
    topSection = (
      <div className={ classes.affiliatePromoPopupBalance }>
        { t('purchase.balance', { amount: `${ amount }` }) }
      </div>
    );
  }
  return topSection;
};

const getErrorButtonTitle = ({
  recoverable, retriable, errorType, t
}) => {
  if (recoverable || retriable) return t('general.retry');
  if (errorType === 'spending_limits') return t('paypal_purchase.increase_spending_limits');
  return t('general.ok');
};

const getConfirmBtnColor = ({ isSideMenu, isAffiliatePromoPopup, chatType }) => {
  if (isSideMenu || isAffiliatePromoPopup) return shared.appColor;
  switch (chatType) {
    case Const.chatType.text:
      return '#6eda0f';
    case Const.chatType.voice:
      return '#8D63EB';
    case Const.chatType.video:
      return '#0AC5F1';
    default:
      return null;
  }
};

const renderStatementMessage = ({ isCreditCard }) => {
  const { config: { paypalStatementNote, creditCardStatementNote } } = window;
  if (!paypalStatementNote || !creditCardStatementNote) return null;
  const statementText = isCreditCard ? creditCardStatementNote : paypalStatementNote;
  return (
    <span className = { classes.smallInfo }>
      { parse(`${ statementText }`) }
    </span>
  );
};

const getHref = ({ errorType, purchaseURL  }) => {
  if (errorType === 'spending_limits') return appRoutes.spendLimits;
  return purchaseURL;
};

const renderBottomSectionLoadingIfNeed = ({
  okMessage, errorMessage, error, userLoading, bottomSection
}) => {
  let bottom = bottomSection;
  if ((!okMessage && !errorMessage && !error) || userLoading) {
    bottom = (
      <Spiner className={ classes.spiner } loaderColor={ shared.appColor } />
    );
  }
  return bottom;
};

const cashbackInfo = (t, cashbackAmount) => {
  if (!cashbackAmount) return null;
  return (
    <span className= { classes.smallInfo }>
      { t('paypal_purchase.purchase_earn') }
      <b>{ ` $${ parseFloat(cashbackAmount).toFixed(2) } ` }</b>
      { t('paypal_purchase.cashback') }
    </span>
  );
};

export const getBottomSection = ({
  okMessage, errorMessage, error, userLoading, errorType, purchaseURL, onRetry, recoverable,
  retriable, t, isAffiliatePromoPopup, onConfirm, buttonTitle, cashbackAmount, isSideMenu, chatType,
  isCreditCard
}) => {
  let bottomSection = null;
  if (errorMessage || error) {
    bottomSection = (
      <div className={ classes.botomContainer }>
        <div className={ classes.errorButtonContainer }>
          <a
            href={ getHref({ errorType, purchaseURL }) }
            target="blank"
            className={ classes.errorA }
            onClick={ onRetry }
          >
            <div className={ classes.confirmButtonA }>
              { getErrorButtonTitle({
                recoverable, retriable, errorType, t
              }) }
            </div>
          </a>
        </div>
      </div>
    );
  } else if (isAffiliatePromoPopup) {
    bottomSection = (
      <div className={ classes.botomContainer }>
        <div className={ classes.smallInfoContainerAffiliatePromoPopup }>
          <span className= { classes.affiliatePromoPopupContactUsText }>
            { t('purchase.if_you_need_anything_from_us') }
            <a href={ contactUsLink.contactUs } className={ classes.affiliatePromoPopupContactUsLink }>
              { t('purchase.contact_us') }
            </a>
            { t('purchase.any_time') }
          </span>
        </div>
        <button
          type="button"
          onClick={ onConfirm }
          className={ classes.confirmButton }
          style={ { '--color': getConfirmBtnColor({ isSideMenu, isAffiliatePromoPopup, chatType }) } }
        >
          { buttonTitle }
        </button>
      </div>
    );
  } else {
    bottomSection = (
      <div className={ classes.botomContainer }>
        <button
          type="button"
          onClick={ onConfirm }
          className={ classes.confirmButton }
          style={ { '--color': getConfirmBtnColor({ isSideMenu, isAffiliatePromoPopup, chatType }) } }
        >
          { buttonTitle }
        </button>
        <div className={ classes.smallInfoContainer }>
          {cashbackInfo(t, cashbackAmount)}
          { renderStatementMessage({ isCreditCard }) }
        </div>
      </div>
    );
  }
  bottomSection = renderBottomSectionLoadingIfNeed({
    okMessage, errorMessage, error, userLoading, bottomSection
  });
  return bottomSection;
};

const getDefaultMessage = ({ error, isCreditCard, t }) => {
  if (error) return `Error: ${ error.text }`;
  if (isCreditCard) return <Trans i18nKey="purchase.processing_payment" />;
  return t('purchase.your_payment_is_processing_in_another_window');
};

export const renderGeneralMessage = ({
  isAffiliatePromoPopup, okMessage, bonus, errorMessage, error, isCreditCard,
  onFocusProcessingWindow, t
}) => {
  if (isAffiliatePromoPopup && okMessage) {
    return (
      <span className={ classes.affiliatePromoPopupMessage }>
        <Trans
          i18nKey="app_purchase.affiliate_promo_popup_congratulations"
          components={ {
            normal:<p className={ classes.normalText } />,
            purpleText:<div className={ classes.purpleText } />
          } }
          values={ { amount: bonus } }
        />
      </span>
    );
  }
  const messageButton = !okMessage && !errorMessage && !isCreditCard ? (
    <button className={ classes.redirectButton } type="button" onClick={ onFocusProcessingWindow }>
      here
    </button>
  ) : null;
  return (
    <span className={ classes.message }>
      { okMessage || errorMessage || getDefaultMessage({ error, isCreditCard, t }) }
      { messageButton }
    </span>
  );
};
